@import 'colors';
@import 'media-vars';
#cmodal{
  position: fixed;
  @media(max-width: 1000px){
    position: absolute;
  }
  width: 100%;
  height: 100%;
  z-index: 90;
  display: none;

  .cbtn{
    border-radius: 0;
    background-color: $col-red;
    padding: 10px 30px;
    line-height: 30px;
    text-decoration: none;
    font-weight: 700;
    color: #fff;
    font-size: 12px;
    margin-top: 20px;
    display: block;
    height: 50px;
    text-align: center;
    transition: background-color 1s;
    &:hover{
      background-color: $col-red-strong;
    }
  }

  #player{
    margin-left: auto;
    margin-right: auto;
    width: 640px;
    height: 360px;
    display: block;
    @media(max-width: 992px){
      width: 80%;
      height: 300px;
    }
    @media(max-width: 640px){
      width: 90%;
      height: 300px;
    }
    @media(max-width: 440px){
      width: 95%;
      height: 200px;
    }
    @media(max-width: 380px){
      width: 100%;
      height: 150px;
    }
  }
  .bclose{
    width: 100%;
    height: 100%;
    min-width: 100%;
    min-height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 999;
    cursor: pointer;
    background-color: rgba(0,0,0,0.5);
    @media(max-width: 1000px){
      height: 2000px;
      min-height: 2000px;
    }
  }
  .box{
    background-color: #000;
    width: 50%;
    padding: 20px;
    border-radius: 10px;
    position: absolute;
    top:50%;
    left: 50%;
    z-index: 9999;
    transform: translate(-50%,-50%);
    h2{
      display: block;
      text-align: center;
      text-transform: uppercase;
      letter-spacing: 5px;
      @media(max-width: 640px){
        padding-top: 50px;
      }
      @media(max-width: 380px){
        padding-top: 70px;
      }
    }
    @media(max-width: 1400px){
      width: 80%;
    }
    @media(max-width: 1000px){
      transform: translate(-50%,0%);
      top:10%;
    }
    @media(max-width: 375px){
      width: 90%;
      padding: 10px;
    }
    a{

      &.cclose{
        position: absolute;
        right: 20px;
        top:0px;
        display: block;
        font-size: 14px;
        @media(max-width: 440px){
          padding: 10px;
          height: 30px;
          line-height: 10px;
        }
      }
      &.red{
        width: 200px;
        display: block;
        text-align: center;
        margin-left: auto;
        margin-right: auto;
      }
    }
    p{
      font-size: 16px;
      text-align: center;
      padding: 20px;
      clear: both;
      @media(max-width: 375px){
        font-size: 14px;
      }
    }
  }
}
