@import 'colors';
@import 'media-vars';
body{
  font-family: 'Montserrat', sans-serif;
  color: #fff;
  font-weight: 600;
  overflow-x: hidden;
}



.white-front{
  width: 100%;
  height: 100%;
  background-color: #fff;
  z-index: 20;
  position: fixed;
}

.arrowslide{
  animation: arrow-slide linear 2s;
  animation-iteration-count: infinite;
}

#header{
  height: 600px;
  background-image: url('../images/bg.jpg');
  background-position: center;
  position: relative;
  overflow: hidden;
  video{
    position: absolute;
    left:50%;
    top:50%;
    min-width: 100%;
    z-index: 0;
    transform: translate(-50%,-50%);
  }
  #arrow{
    width: 50px;
    height: 50px;
    z-index: 10;
    position: absolute;
    bottom:-10px;
    left: 50%;

    cursor: pointer;
    transform: translate(-50%,-50%);
    img{
      display: block;
      margin: auto;
      position: absolute;
    }
  }
  #branding{
    padding-top: 20px;
    #autowitolin{

    }
    #honda{
      float: right;
    }
    img{
      @media(max-width: 1200px){
        width: 150px;
        height: auto;
      }
      @media(max-width: 440px){
        width: 100px;
        height: auto;
      }
    }
  }
  #main-title{
    margin-top: 20px;
    @media(max-width: 1200px){
      margin-top: 40px;
    }
    img{
      width: 100%;
      height: auto;
    };
  }
  #btns{
    .anchor-btn{
      cursor: pointer;
      img{
        animation: opacityLights linear 2s;
        animation-iteration-count: infinite;
      }
      &:hover{
        a{
          opacity: 0.5;
        }
        img{
          transform: scale(0.5,0.5) translate(-100%,-100%);

        }
      }
    }
    .photo-box{
      position: relative;
      width: 100%;
      height: 180px;

      &#date{
        height: 100px;
        @media(max-width: 992px){
          height: 80px;
        }
        img{
          width: 100%;
          height: auto;
          @media(max-width: 992px){
            width: 70%;
          }
          @media(max-width: 440px){
            width: 100%;
          }
        }
      }
      img{
        position: absolute;
        left: 50%;
        top:50%;
        transform: translate(-50%,-50%);
        transition: transform 1s;

      }
    }

    a{
      font-size: 18px;
      color: #fff;
      text-decoration: none;
      width: 100%;
      text-align: center;
      display: block;
      transition: opacity 1s;
      @media(max-width: 992px){
        font-size: 16px;
      }

      @media(max-width: 440px){
        font-size: 12px;
      }
    }
    #logo-type-r{
      @media(max-width: 992px){
        width: 200px;
        height: auto;
      }
    }
    img{
      display: block;
      margin: auto;
    }
  }

}

h2{
  color: $col-red;
  font-size: 24px;
  font-weight: 700;
  padding-bottom: 20px;
  strong{
    font-weight: 800;
  }
}
p{
  font-size: 12px;
  font-weight: 600;
  line-height: 18px;
}

.animate-light{
  animation: opacityLightsIcon linear 4s;
  animation-iteration-count: infinite;
}

#form{
  padding-top: 40px;
  padding-bottom: 40px;
  background-color: #fff;
  color: $col-dark;
  .photos{
    height: 400px;
    @media(max-width: 640px){
      height: 300px;
    }
    img, .img-icon{
      position: absolute;
    }
    .img-icon{
      width: 450px;
      height: 260px;
      img{
        width: 100%;
        height: auto;
      }
    }
    .img-1{
      top:0;
      left: 0;
      @media(max-width: 1200px){
        width: 70%;
        height: auto;
      }
      @media(max-width: 992px){
        width: 50%;
        height: auto;
      }
      @media(max-width: 440px){
        width: 70%;
      }
    }
    .img-2{
      right: 5%;
      top:40%;
      @media(max-width: 1200px){
        width: 70%;
        height: auto;
      }
      @media(max-width: 992px){
        width: 50%;
        top:inherit;
        bottom: 0;
        height: auto;
      }
      @media(max-width: 440px){
        width: 70%;
      }
    }
    .img-3{
      top:80%;
      left: 0;
      @media(max-width: 992px){
        top:inherit;
        bottom: 0;
      }
      @media(max-width: 992px){
        width: 50%;
        height: auto;
        left: 10%;
      }
      @media(max-width: 440px){
        width: 60%;
        height: auto;
        left: 20%;
        bottom: 20%;
      }
    }
    .img-4{
      left: 20%;
      top:40%;
      @media(max-width: 1200px){
        width: 70%;
        height: auto;
        left: 0;
      }
      @media(max-width: 992px){
        width: 50%;
        height: auto;
        top:20%;
        left: 25%;
      }
      @media(max-width: 640px){
        width: 70%;
        left: 10%;
      }
    }
  }
  .txt{
    @media(max-width: 992px){
      margin-top: 60px;
    }
  }
  input{
    background-color: #000;
    padding: 10px;
    border-radius: 10px;
    color: #fff;
    font-size: 12px;
    &[type='submit']{
      border-radius: 0;
      background-color: $col-red;
      padding-left: 30px;
      padding-right: 30px;
      transition: background-color 1s;
      &:hover{
        background-color: $col-red-strong;
      }
    }
  }
  select{
    background-color: #000;
    padding: 10px;
    border-radius: 10px;
    color: #fff;
    font-size: 12px;
    width: 98%;
    background-image: url('../images/arrow-select.png');
    background-position: center right 0px;
    background-repeat: no-repeat;
    margin-top: 10px;
    margin-bottom: 10px;
  }
  .parsley-custom-error-message{
    color: red;
    font-size: 10px;
    padding-top: 10px;
  }
  .agreement-box{
    display: table;
    margin-bottom: 20px;
    &.first{
      margin-top: 20px;
    }
    span{
      display: block;
      width: 20px;
      height: 20px;
    }
    input{
      cursor: pointer;
      background-color: inherit;
      border-radius: 5px;
      padding: 0px;
      width: 20px;
      height: 20px;
      background-position: center;
      background-image: url('../images/un-checked.jpg');
      background-repeat: no-repeat;
      &:checked{
        background-image: url('../images/checked.jpg');
      }
    }
    label{
      cursor: pointer;
      display: table-cell;
      font-size: 10px;
      line-height: 14px;
      font-weight: 600;
      padding-left: 10px;
    }
    p{
      font-size: 8px;
    }
  }
  ul{
    li{
      vertical-align: top;
    }
  }
  .half{
    width: 49%;
    display: inline-block;
    margin-top: 10px;
    margin-bottom: 10px;
    @media(max-width: 640px){
      width: 100%;
    }
    input{
      width: 90%;
      @media(max-width: 640px){
        width: 94%;
      }
    }
  }
}

#place{
  background-color: $col-dark;
  padding-top: 40px;
  padding-bottom: 40px;
  h2{
    margin-top: 100px;
    @media(max-width: 992px){
      margin-top: 50px;
    }
  }
  .btn{
    border-radius: 0;
    background-color: $col-red;
    padding-left: 30px;
    padding-right: 30px;
    font-weight: 700;
    color: #fff;
    font-size: 12px;
    margin-top: 20px;
    transition: background-color 1s;
    &:hover{
      background-color: $col-red-strong;
    }
  }
  .txt{
    @media(max-width: 992px){
      margin-bottom: 40px;
    }
  }
  .photos{
    height: 500px;
    position: relative;
    @media(max-width: 440px){
      height: 300px;
    }
    img, .img-icon{
      position: absolute;
    }
    .img-icon{
      width: 230px;
      height: 290px;
      img{
        width: 100%;
        height: auto;
      }
    }
    .img-1{
      top:0;
      left: 0;
      z-index: 2;
      @media(max-width: 1200px){
        width: 70%;
        height: auto;
        left: 0;
      }
      @media(max-width: 992px){
        width: 50%;
        height: auto;
      }
      @media(max-width: 640px){
        width: 70%;
      }
    }
    .img-2{
      right: 5%;
      top:40%;
      @media(max-width: 1200px){
        width: 80%;
        height: auto;
      }
      @media(max-width: 992px){
        width: 50%;
        height: auto;
      }
      @media(max-width: 640px){
        width: 70%;
      }
    }
    .img-3{
      left: 30%;
      top:25%;
      z-index: 3;
      @media(max-width: 992px){
        top:15%;
      }
      @media(max-width: 640px){
        width: 50%;
        left: 20%;
      }
    }

  }
}

footer{
  background-color: $col-red;
  img{
    margin-top: 10px;
    margin-bottom: 10px;
    @media(max-width: 440px){
      margin-left: auto;
      margin-right: auto;
      display: block;
    }
  }
  p{
    float: right;
    margin-top: 20px;
    text-align: center;
    @media(max-width: 440px){
      float: none;
      display: block;
      margin-top: 20px;
      margin-bottom: 20px;
    }
    a{
      color: #fff;
      text-decoration: none;
      padding-left: 30px;
      @media(max-width: 440px){
        padding-left: inherit;
        display: block;
      }
    }
  }
}
.thankyou, .error{
  background-color: $col-red;
  #header{
    background-image: url('../images/bg-thankyou.jpg');

    h1{
      font-size: 24px;
      font-weight: 700;
      line-height: 30px;
      text-align: center;
    }
    p{
      text-align: center;
      display: block;
      margin-left: auto;
      margin-right: auto;
      width: 50%;
      margin-top: 50px;
      font-size: 16px;
      line-height: 20px;
      @media(max-width: 992px){
        width: 90%;
      }
    }
    ul{
      margin-top: 40px;
      text-align: center;
      li{
        display: inline-block;
        margin-left: 10px;
        margin-right: 10px;
        a{
          border-radius: 0;
          background-color: $col-red;
          padding:10px 30px;
          font-weight: 700;
          color: #fff;
          font-size: 12px;
          display: block;
          margin-top: 20px;
          transition: background-color 1s;
          text-decoration: none;
          &:hover{
            background-color: $col-red-strong;
          }
        }
      }
    }

  }
}

.cc-message{
  font-size: 12px;
}
